import { Component, Inject, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EcniDocument } from '../../models/ecni-document.model';
import { ItemImage } from '../../models/item.model';
import { ModalListImagesData } from '../../models/modal-data';

@Component({
  selector: 'app-modal-list-images',
  templateUrl: './modal-list-images.component.html',
  styleUrls: ['./modal-list-images.component.scss'],
})
export class ModalListImagesComponent implements OnInit, AfterViewInit {
  images: (EcniDocument | ItemImage)[];
  currentImageIndex: number;

  viewIsInitialized = false;
  currentImageIsZoomed = false;
  @ViewChild('currentImage') currentImage: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ModalListImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalListImagesData
  ) {
    this.images = data.images;
    this.currentImageIndex = data.currentImageIndex;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewIsInitialized = true;
    });
  }

  previousImage() {
    this.currentImageIndex--;
    if (this.currentImageIndex < 0) {
      this.currentImageIndex = this.images.length - 1;
    }
    this.currentImageIsZoomed = false;
  }

  nextImage() {
    this.currentImageIndex++;
    if (this.currentImageIndex > this.images.length - 1) {
      this.currentImageIndex = 0;
    }
    this.currentImageIsZoomed = false;
  }

  toggleZoom() {
    if (this.currentImageCanBeZoomed && !this.currentImageIsZoomed) {
      this.currentImageIsZoomed = true;
    } else if (this.currentImageIsZoomed) {
      this.currentImageIsZoomed = false;
    }
  }

  close() {
    this.dialogRef.close();
  }

  get currentImageCanBeZoomed() {
    if (
      this.viewIsInitialized &&
      this.currentImage &&
      this.currentImage.nativeElement.naturalWidth > this.currentImage.nativeElement.clientWidth
    ) {
      return true;
    } else {
      return false;
    }
  }
}
